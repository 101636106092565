import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { createSelectors } from '~utils/createSelectors'

export type DisclosureStore = {
  isEditDrawerOpen: boolean
  onEditDrawerOpen: () => void
  onEditDrawerClose: () => void
  onEditDrawerToggle: () => void
  isDirtyModalDisclosureOpen: boolean
  onDirtyModalDisclosureOpen: () => void
  onDirtyModalDisclosureClose: () => void
  onDirtyModalDisclosureToggle: () => void
  isCreateWorkspaceModalDisclosureOpen: boolean
  onCreateWorkspaceModalDisclosureOpen: () => void
  onCreateWorkspaceModalDisclosureClose: () => void
  onCreateWorkspaceModalDisclosureToggle: () => void
  isRenameWorkspaceModalDisclosureOpen: boolean
  onRenameWorkspaceModalDisclosureOpen: () => void
  onRenameWorkspaceModalDisclosureClose: () => void
  onRenameWorkspaceModalDisclosureToggle: () => void
  isDeleteWorkspaceModalDisclosureOpen: boolean
  onDeleteWorkspaceModalDisclosureOpen: () => void
  onDeleteWorkspaceModalDisclosureClose: () => void
  onDeleteWorkspaceModalDisclosureToggle: () => void
  isShareWorkspaceModalDisclosureOpen: boolean
  onShareWorkspaceModalDisclosureOpen: () => void
  onShareWorkspaceModalDisclosureClose: () => void
  onShareWorkspaceModalDisclosureToggle: () => void
  isWorkspaceLogsModalDisclosureOpen: boolean
  onWorkspaceLogsModalDisclosureOpen: () => void
  onWorkspaceLogsModalDisclosureClose: () => void
  onWorkspaceLogsModalDisclosureToggle: () => void
  isWelcomeModalDisclosureOpen: boolean
  onWelcomeModalDisclosureOpen: () => void
  onWelcomeModalDisclosureClose: () => void
  onWelcomeModalDisclosureToggle: () => void
  isShareMediaModalDisclosureOpen: boolean
  onShareMediaModalDisclosureOpen: () => void
  onShareMediaModalDisclosureClose: () => void
  onShareMediaModalDisclosureToggle: () => void
  isDeleteMediaModalDisclosureOpen: boolean
  onDeleteMediaModalDisclosureOpen: () => void
  onDeleteMediaModalDisclosureClose: () => void
  onDeleteMediaModalDisclosureToggle: () => void
  isCopyMediaModalDisclosureOpen: boolean
  onCopyMediaModalDisclosureOpen: () => void
  onCopyMediaModalDisclosureClose: () => void
  onCopyMediaModalDisclosureToggle: () => void
  isDestroyMediaModalDisclosureOpen: boolean
  onDestroyMediaModalDisclosureClose: () => void
  onDestroyMediaModalDisclosureOpen: () => void
  onDestroyMediaModalDisclosureToggle: () => void
}

const DisclosureStore = create<DisclosureStore>()(
  devtools(
    (set, get) => ({
      isEditDrawerOpen: false,
      onEditDrawerOpen: () => set({ isEditDrawerOpen: true }),
      onEditDrawerClose: () => set({ isEditDrawerOpen: false }),
      onEditDrawerToggle: () =>
        set({ isEditDrawerOpen: !get().isEditDrawerOpen }),
      isDirtyModalDisclosureOpen: false,
      onDirtyModalDisclosureOpen: () =>
        set({ isDirtyModalDisclosureOpen: true }),
      onDirtyModalDisclosureClose: () =>
        set({ isDirtyModalDisclosureOpen: false }),
      onDirtyModalDisclosureToggle: () =>
        set({
          isDirtyModalDisclosureOpen: !get().isDirtyModalDisclosureOpen,
        }),
      isCreateWorkspaceModalDisclosureOpen: false,
      onCreateWorkspaceModalDisclosureOpen: () =>
        set({ isCreateWorkspaceModalDisclosureOpen: true }),
      onCreateWorkspaceModalDisclosureClose: () =>
        set({ isCreateWorkspaceModalDisclosureOpen: false }),
      onCreateWorkspaceModalDisclosureToggle: () =>
        set({
          isCreateWorkspaceModalDisclosureOpen:
            !get().isCreateWorkspaceModalDisclosureOpen,
        }),
      isDeleteWorkspaceModalDisclosureOpen: false,
      onDeleteWorkspaceModalDisclosureOpen: () =>
        set({ isDeleteWorkspaceModalDisclosureOpen: true }),
      onDeleteWorkspaceModalDisclosureClose: () =>
        set({ isDeleteWorkspaceModalDisclosureOpen: false }),
      onDeleteWorkspaceModalDisclosureToggle: () =>
        set({
          isDeleteWorkspaceModalDisclosureOpen:
            !get().isDeleteWorkspaceModalDisclosureOpen,
        }),
      isRenameWorkspaceModalDisclosureOpen: false,
      onRenameWorkspaceModalDisclosureOpen: () =>
        set({ isRenameWorkspaceModalDisclosureOpen: true }),
      onRenameWorkspaceModalDisclosureClose: () =>
        set({ isRenameWorkspaceModalDisclosureOpen: false }),
      onRenameWorkspaceModalDisclosureToggle: () =>
        set({
          isRenameWorkspaceModalDisclosureOpen:
            !get().isRenameWorkspaceModalDisclosureOpen,
        }),
      isShareWorkspaceModalDisclosureOpen: false,
      onShareWorkspaceModalDisclosureOpen: () =>
        set({ isShareWorkspaceModalDisclosureOpen: true }),
      onShareWorkspaceModalDisclosureClose: () =>
        set({ isShareWorkspaceModalDisclosureOpen: false }),
      onShareWorkspaceModalDisclosureToggle: () =>
        set({
          isShareWorkspaceModalDisclosureOpen:
            !get().isShareWorkspaceModalDisclosureOpen,
        }),
      isWorkspaceLogsModalDisclosureOpen: false,
      onWorkspaceLogsModalDisclosureOpen: () =>
        set({ isWorkspaceLogsModalDisclosureOpen: true }),
      onWorkspaceLogsModalDisclosureClose: () =>
        set({ isWorkspaceLogsModalDisclosureOpen: false }),
      onWorkspaceLogsModalDisclosureToggle: () =>
        set({
          isWorkspaceLogsModalDisclosureOpen:
            !get().isWorkspaceLogsModalDisclosureOpen,
        }),
      isWelcomeModalDisclosureOpen: false,
      onWelcomeModalDisclosureOpen: () =>
        set({ isWelcomeModalDisclosureOpen: true }),
      onWelcomeModalDisclosureClose: () =>
        set({ isWelcomeModalDisclosureOpen: false }),
      onWelcomeModalDisclosureToggle: () =>
        set({
          isWelcomeModalDisclosureOpen: !get().isWelcomeModalDisclosureOpen,
        }),
      isShareMediaModalDisclosureOpen: false,
      onShareMediaModalDisclosureOpen: () =>
        set({ isShareMediaModalDisclosureOpen: true }),
      onShareMediaModalDisclosureClose: () =>
        set({ isShareMediaModalDisclosureOpen: false }),
      onShareMediaModalDisclosureToggle: () =>
        set({
          isShareMediaModalDisclosureOpen:
            !get().isShareMediaModalDisclosureOpen,
        }),
      isDeleteMediaModalDisclosureOpen: false,
      onDeleteMediaModalDisclosureOpen: () =>
        set({ isDeleteMediaModalDisclosureOpen: true }),
      onDeleteMediaModalDisclosureClose: () =>
        set({ isDeleteMediaModalDisclosureOpen: false }),
      onDeleteMediaModalDisclosureToggle: () =>
        set({
          isDeleteMediaModalDisclosureOpen:
            !get().isDeleteMediaModalDisclosureOpen,
        }),
      isCopyMediaModalDisclosureOpen: false,
      onCopyMediaModalDisclosureOpen: () =>
        set({ isCopyMediaModalDisclosureOpen: true }),
      onCopyMediaModalDisclosureClose: () =>
        set({ isCopyMediaModalDisclosureOpen: false }),
      onCopyMediaModalDisclosureToggle: () =>
        set({
          isCopyMediaModalDisclosureOpen: !get().isCopyMediaModalDisclosureOpen,
        }),
      isDestroyMediaModalDisclosureOpen: false,
      onDestroyMediaModalDisclosureOpen: () =>
        set({ isDestroyMediaModalDisclosureOpen: true }),
      onDestroyMediaModalDisclosureClose: () =>
        set({ isDestroyMediaModalDisclosureOpen: false }),
      onDestroyMediaModalDisclosureToggle: () =>
        set({
          isDestroyMediaModalDisclosureOpen:
            !get().isDestroyMediaModalDisclosureOpen,
        }),
    }),
    { enabled: process.env.NODE_ENV !== 'production' },
  ),
)

export const useCloseAllWorkspaceModals: () => () => void = () => {
  const closeCreateWorkspaceModal =
    useDisclosureStore.use.onCreateWorkspaceModalDisclosureClose()
  const closeRenameWorkspaceModal =
    useDisclosureStore.use.onRenameWorkspaceModalDisclosureClose()
  const closeDeleteWorkspaceModal =
    useDisclosureStore.use.onDeleteWorkspaceModalDisclosureClose()
  const closeShareWorkspaceModal =
    useDisclosureStore.use.onShareWorkspaceModalDisclosureClose()
  const closeWorkspaceLogsModal =
    useDisclosureStore.use.onWorkspaceLogsModalDisclosureClose()
  const closeDeleteMediaModal =
    useDisclosureStore.use.onDeleteMediaModalDisclosureClose()
  const closeShareMediaModal =
    useDisclosureStore.use.onShareMediaModalDisclosureClose()
  const closeCopyMediaModal =
    useDisclosureStore.use.onCopyMediaModalDisclosureClose()
  const closeDestroyMediaModal =
    useDisclosureStore.use.onDestroyMediaModalDisclosureClose()
  return () => {
    closeCreateWorkspaceModal()
    closeRenameWorkspaceModal()
    closeDeleteWorkspaceModal()
    closeShareWorkspaceModal()
    closeWorkspaceLogsModal()
    closeDeleteMediaModal()
    closeShareMediaModal()
    closeCopyMediaModal()
    closeDestroyMediaModal()
  }
}

export const useDisclosureStore = createSelectors(DisclosureStore)
