export const routes = {
  index: '/',
  dashboard: '/dashboard',
  login: '/login',
  health: '/health',
  gallery: '/gallery',
  galleryWithWorkspace: '/gallery/workspace',
  galleryWithWorkspaceId: '/gallery/workspace/:workspaceId',
  shareLink: '/link/:uuid',
  recycleBin: '/recycle-bin',
}
